import React from 'react'
import NavBar from './NavBar'
import Footer from './Footer'

export default props => {
    return (
        <>
            <NavBar color={props.color}/>
                {props.children}
            <Footer/>
        </>
    )
}

