import React from 'react'
import Layout from './Layout'
import {graphql, Link} from 'gatsby'
import timer from '../images/icon-timer.png'

export default ({data:{receta:{nombre,tiempoDePreparacion,ingredientes:{ingredientes},preparacion:{preparacion},fotoDePortada:{fluid}}}}) => {
    // console.log(props)
    return(
        <Layout className="grid grid-cols-2">
            <h1 className="m-auto w-1/2 text-center pt-24 text-3xl text-blue-500">Receta de {nombre}</h1>
            <article className="bg-blue-100 w-1/2 m-auto rounded-lg my-12 p-12 ">
            <h1 className="mb-4 text-xs flex"><img src={timer} width="20px" />Tiempo de Preparación: {tiempoDePreparacion} minutos</h1>
            <h1 className="text-blue-500 text-2xl mb-4">Ingredientes:</h1>
            <p className="mb-8">{ingredientes}</p>
            <h1 className="text-blue-500 text-2xl mb-4">Preparación:</h1>
            <p className="mb-8">{preparacion}</p>
            
            <Link to='../../' className="transition duration-200 hover:bg-blue-500 text-center p-4 fondo-azul-mayalav rounded-md text-white">Regresar a Recetas</Link>
            </article>
        </Layout>
    )
}

export const query = graphql`
    query GetSingleReceta($slug: String) {
        receta: contentfulReceta (slug:{eq:$slug}) {
            nombre
            slug
            tiempoDePreparacion
            preparacion {
                preparacion
            }
            fotoDePortada {
                fluid {
                    ...GatsbyContentfulFluid
                }
            }
            ingredientes {
                ingredientes
            }
        }
    }
`